import React, {useEffect} from 'react';

export default function AdminTemplate(props) {

    function checkToken() {
        const token = localStorage.getItem('token');
        
        if(token == null) {
            props.history.push('/login');
            return (<section></section>);
        } else {
            return (
                <div>
                    <header>
                        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                            <a className="navbar-brand" href="/url/dashboard">
                                QRCliq
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="/url/dashboard">Home <span className="sr-only">(current)</span></a>
                                    </li>                        
                                </ul>
                                <div className="mt-2 mt-md-0">                            
                                    <a href="/logout" className="btn btn-outline-warning my-2 my-sm-0">Logout</a>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <main role="main" className="flex-shrink-0" style={{padding: '56px 0px 0px'}}>
                        <div className="container-fluid px-0">
                            {props.children}
                        </div>
                    </main>
                </div>
            )
        }
    }

    return checkToken();
}