import React from 'react';
import QRList from './qr-list-component';

export default function DashboardScreen() {
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/url/dashboard">Home</a></li>
                </ol>
            </nav>
            <QRList/>
        </div>
    )
}