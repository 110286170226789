import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './index.css';
import * as serviceWorker from './serviceWorker';

import LoginTemplate from './template/login-template';
import AdminTemplate from './template/admin-template';
import LoginScreen from './login/login-screen';
import DashboardScreen from './url/dashboard-screen';
import AppRoute from './approute';
import EditURLScreen from './url/edit-url-screen';
import LogoutScreen from './logout/logout-screen';

ReactDOM.render(
    <Router>
        <Switch>
            <AppRoute exact path="/" layout={LoginTemplate} component={LoginScreen} />
            <AppRoute exact path="/login" layout={LoginTemplate} component={LoginScreen} />
            {/* Admin Routes */}
            <AppRoute path="/url/edit/:guid" layout={AdminTemplate} component={EditURLScreen} />
            <AppRoute exact path="/url/dashboard" layout={AdminTemplate} component={DashboardScreen} />
            <AppRoute exact path="/logout" layout={AdminTemplate} component={LogoutScreen} />
        </Switch>
    </Router>    
, document.getElementById('render'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
