import React, {useEffect} from 'react';

export default function LogoutScreen(props) {

    useEffect(() => {
        // clear local storage
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');

        //redirect to login
        props.history.push('/login');
    });

    return (
        <section></section>
    )
}