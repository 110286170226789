import React, {useState} from 'react';
import axios from 'axios';

export default function LoginScreen(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState();

    const handleChanges = (event) => {
        switch(event.target.name) {
            case 'username':
                setUsername(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;

            default:
                break;
        }
    }

    async function login() {
        
        setAlert();
        if(username !== '' || password !== '') {
            const result = await axios.post('https://qrcliq.com/api/login',{
                username: username,
                password: password
            });

            if(result.data.success === 200) {
                // save token to localstorage
                localStorage.setItem("token", result.data.result.token);
                localStorage.setItem("refreshToken", result.data.result.refreshToken);
                // redirect to dashboard routes
                props.history.push('/url/dashboard');
            } else {
                setAlert(
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-danger" role="alert">
                                Oppss..Wrong username or password
                            </div>                    
                        </div>
                    </div>
                );
            }
        } else {
            setAlert(
                <div className="row">
                    <div className="col-md-12">
                        <div className="alert alert-danger" role="alert">
                            Please fill in all the required details
                        </div>                    
                    </div>
                </div>
            )
        }
    }

    return (
        <section className="login-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="mb-0">QRCLIQ</h1>                   
                                <p className="subheader">Generate dynamic QR code</p>
                            </div>                            
                        </div>
                        {alert}
                        <div className="row">
                            <div className="col-md-12 mb-1">
                                <input type="text" className="form-control" placeholder="Username" name="username" autoComplete="off" onChange={(e) => handleChanges(e)} />
                            </div>
                            <div className="col-md-12 mb-1">
                                <input type="password" className="form-control" placeholder="Password" name="password" autoComplete="off" onChange={(e) => handleChanges(e)} />
                            </div>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-primary btn-block btn-mcliq" onClick={()=>login()}>Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    );
}