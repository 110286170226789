import React, { useState, useEffect} from 'react';
import axios from 'axios';

export default function EditURLScreen(props) {
    const { match: { params } } = props;
    const [alert, setAlert] = useState();
    const [url, setURL] = useState('');
    const [shortid, setShortid] = useState('');
    const [qr, setQR] = useState({});

    useEffect(() => {
        getQR();
    }, []);

    const handleInputChanges = (event) => {  
        switch(event.target.name) {
            case 'url':
                setURL(event.target.value);
                break;
            
            case 'shortid':
                setShortid(event.target.value);
                break;

            default:
                break;
        }
    }

    async function getQR() {
        const token = localStorage.getItem('token');
        const result = await axios.get('https://qrcliq.com/api/url/' + params.guid
        ,{
            headers: {
                authorization: 'Bearer '+token.toString()
            }
        });

        setQR(result.data.result);
        setURL(result.data.result.url);
        setShortid(result.data.result.shortid);
    }

    async function updateQR() {
        const result = validation(url, shortid);
        
        if(result) {
            const token = localStorage.getItem('token');
            const result = await axios.put('https://qrcliq.com/api/url/' + qr.guid,
            {
                url: url,
                shortid: shortid                
            },{
                headers: {
                    authorization: 'Bearer '+token.toString()
                }
            });

            if(result.data.success === 200) {
                getQR();
                setAlert(
                    <div class="alert alert-success" role="alert">
                        <strong>Updated!</strong> QR code safely updated
                    </div>
                );
                
            }

            console.log(result);
        } else {

        }
        
    }

    function validation(shortid, url) {
        setAlert('');
        if(shortid === '' || url === '') {
            setAlert(
            <div className="row">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                        Please all the required fields
                    </div>                    
                </div>
            </div>
            );
            return false;
        }

        return true;
    }

    

    return (
        <section>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/url/dashboard">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{qr.shortid}</li>
                </ol>
            </nav>
            <div className="row mb-5">
                <div className="col-md-3">
                    <img src={'https://qrcliq.com/qr/'+ qr.shortid + '.png'} style={{width: '100%'}} alt="qr"/>
                </div>
                <div className="col-md-9 pt-4 px-5">
                    {alert}
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="mb-0"><strong>Short ID</strong></label>
                            <input type="text" name="shortid" className="form-control" value={shortid} onChange={(event) => handleInputChanges(event)}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className="mb-0"><strong>URL</strong></label>
                            <input type="text" name="url" className="form-control" value={url} onChange={(event) => handleInputChanges(event)}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-success" onClick={() => updateQR()}>Update</button>
                        </div>
                    </div>                                        
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-12">
                    <h3>Statistics</h3>
                    <hr/>
                </div>
            </div> */}
        </section>
    );
}