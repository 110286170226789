import React from 'react';
import {Route} from 'react-router-dom';


export default function AppRoute({component: Component, layout: Layout, ...res}) {
    return (
        <Route {...res}
            render={props => {
                return (
                    <Layout {...props}>
                        <Component {...props} />
                    </Layout>
                )
            }}
        />
    );
}